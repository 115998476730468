.errorTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
}

.table {
	:global {
		.ant-table-tbody > tr:hover td {
			background-color: none !important;
		}
	}
}

