.box {
  padding: 12px;
}

.checkboxGroup {
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto;

  label {
    margin-top: 8px;
  }
}

.divider {
  margin: 12px 0px;
}

.actions {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.actionObject {
  margin-bottom: 4px;
  span {
    color: #40a9ff;
  }
}

.errorTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}