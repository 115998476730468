.container {
  display: flex;
  margin-bottom: 20px;
  max-width: 100%;
}

.maxSelect {
  width: 200px;
}

.minSelect {
  width: 100px;
}

.select {
  margin-right: 12px;
}

.searchButton {
  margin-right: 12px;
}

