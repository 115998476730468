@import url(https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto);
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.logo {
  float: left; }

.color-white {
  color: #fff; }

.map-wrap {
  height: 400px; }

.modal__fullwidth {
  width: 90% !important; }

.ymaps-2-1-72-map {
  height: 400px !important; }

.link {
  color: #40a9ff;
  cursor: pointer; }

.map-wrapper {
  margin: 20px 0;
  height: 80vh; }

#root .map-wrapper .ymaps-2-1-72-map {
  height: 80vh !important; }

body .ant-modal-body .ant-checkbox-group-item {
  padding: 5px;
  width: 48%;
  overflow: hidden;
  flex-grow: 1; }

.custom_checkbox_group {
  display: flex;
  flex-wrap: wrap; }
  .custom_checkbox_group .ant-checkbox + span {
    white-space: nowrap;
    display: inline-block;
    width: 90px; }

.button_bind button {
  width: 100%; }

.ant-collapse {
  min-height: 200px; }

.media .ant-radio-group,
.media label {
  width: 100%; }

.media .item {
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #eee;
  margin-bottom: 10px;
  position: relative; }
  .media .item .anticon {
    position: absolute;
    font-size: 18px;
    top: 15px;
    right: 10px; }

.notification__status {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: auto; }

.notification__status--success {
  border: 1px solid #006900;
  background-color: #00f30780; }

.notification__status--warning {
  border: 1px solid #ffc107;
  background-color: #ffc107; }

.notification__status--danger {
  border: 1px solid #b71c1c;
  background-color: #f44336; }

.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: #001529 !important; }

.ant-menu.ant-menu-dark .ant-menu-item-active {
  background-color: #40a9ff !important; }

thead .text-align-bottom {
  vertical-align: bottom; }

.gosuslugy .ant-input-number-handler-wrap {
  display: none; }

.gosuslugy tbody .ant-table-cell,
.gosuslugy thead .ant-table-cell {
  padding: 5px 5px; }

.gosuslugy th,
.gosuslugy td {
  box-sizing: border-box !important; }

.gosuslugy .table-3 .ant-table-row-level-0 td {
  background-color: #ffc48c !important; }

.gosuslugy .table-3 .ant-table-row-level-1 td {
  background-color: #effab4 !important; }

.gosuslugy .table-3 .ant-table-cell-with-append {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.ant-layout-sider-trigger {
  display: none; }

.SingleLayout_modalTitle__20T0b {
  font-weight: 600; }

.SingleLayout_modalMonths__2FKCn {
  color: #026bc2; }

.SingleLayout_buttonWrapper__1bvn1 {
  text-align: center; }

.Error_title__1jffG {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }

.FiltersChart_container__MSEZb {
  display: flex;
  margin-bottom: 20px;
  max-width: 100%; }

.FiltersChart_maxSelect__2DOiB {
  width: 200px; }

.FiltersChart_minSelect__Er7Yb {
  width: 100px; }

.FiltersChart_select__mSv0l {
  margin-right: 12px; }

.FiltersChart_searchButton__20WZT {
  margin-right: 12px; }

.ExcelButton_container__rFhD- {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px; }

.ExcelButton_subtitle__yUCe9 {
  margin: 0;
  padding: 0; }

.Chart_box__24Xpm {
  padding: 12px; }

.Chart_checkboxGroup__3UUge {
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto; }
  .Chart_checkboxGroup__3UUge label {
    margin-top: 8px; }

.Chart_tableButton__3z5dI {
  display: block;
  color: #40a9ff;
  cursor: pointer;
  transition: all 0.3s; }
  .Chart_tableButton__3z5dI:hover {
    color: #026bc2; }

.Chart_divider__2rlSQ {
  margin: 12px 0px; }

.Chart_wrapButton__3Gwgm {
  display: grid;
  grid-template-columns: repeat(2, 100px);
  grid-column-gap: 16px;
  justify-content: space-between; }

.Chart_reportTitle__1HblY {
  cursor: pointer;
  transition: all 0.3s; }
  .Chart_reportTitle__1HblY:hover {
    color: #40a9ff; }

.Chart_table__3QDdN table, .Chart_table__3QDdN td, .Chart_table__3QDdN .ant-table-container {
  border-color: black !important; }

.Chart_table__3QDdN .fizColumn {
  background-color: #e1efda !important; }

.Chart_table__3QDdN .ant-table-thead .yrColumn {
  color: #FF0000 !important;
  background-color: white !important; }

.Chart_table__3QDdN th {
  font-weight: bolder !important;
  border-color: black !important;
  background-color: #e1efda !important; }

.Chart_table__3QDdN .summaryColumn {
  font-weight: bolder !important; }

.ChartBuilder_table__ZNyUV table,
.ChartBuilder_table__ZNyUV td,
.ChartBuilder_table__ZNyUV .ant-table-container {
  border-color: black !important; }

.ChartBuilder_table__ZNyUV .fizColumn {
  background-color: #e1efda !important; }

.ChartBuilder_table__ZNyUV .ant-table-thead .yrColumn {
  color: #ff0000 !important;
  background-color: white !important; }

.ChartBuilder_table__ZNyUV .ant-table-tbody .yrRow .yrColumn {
  background-color: #ff0000 !important; }

.ChartBuilder_table__ZNyUV th {
  font-weight: bolder !important;
  border-color: black !important;
  background-color: #e1efda !important; }

.ChartBuilder_table__ZNyUV .summaryColumn {
  font-weight: bolder !important; }

.ChartBuilder_error__ZaKA7 {
  color: #ff4d4f;
  font-size: 0.9em;
  -webkit-animation: ChartBuilder_fadeIn__2OSAG 0.5s;
          animation: ChartBuilder_fadeIn__2OSAG 0.5s; }

@-webkit-keyframes ChartBuilder_fadeIn__2OSAG {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes ChartBuilder_fadeIn__2OSAG {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.ChartBuilder_input-error__LxSUR {
  border: none;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2); }

.EditChart_error__34BSJ {
  color: #ff4d4f;
  font-size: 0.9em;
  -webkit-animation: EditChart_fadeIn__2CcuT 0.5s;
          animation: EditChart_fadeIn__2CcuT 0.5s; }

@-webkit-keyframes EditChart_fadeIn__2CcuT {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes EditChart_fadeIn__2CcuT {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.EditChart_input-error__3R612 {
  border: none;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2); }

.ChartStatus_errorTitle__3qwbu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh; }

.ChartStatus_table__2K_0I .ant-table-tbody > tr:hover td {
  background-color: none !important; }

.FilterChartStatus_container__TLwQU {
  display: flex;
  margin-bottom: 20px;
  max-width: 100%; }

.FilterChartStatus_select__2xlxz {
  margin-right: 12px;
  width: 200px; }

.FilterChartStatus_maxSelect__1qCv_ {
  width: 500px; }

.FilterChartStatus_minSelect__1xyd2 {
  width: 100px; }

.FilterChartStatus_searchButton__12lpP {
  margin-right: 12px; }

.tabsStyles_addButton__1JIkH {
  margin-bottom: 16px; }

.tabsStyles_select__2Z8ng {
  width: 100%; }

.tabsStyles_search__21QTS {
  margin-left: 20px;
  width: 350px; }

.ModalSettlements_inputNumber__1SRGQ {
  width: 50%; }

.CreateDirectoryInterpreterTab_box__395vP {
  display: flex;
  justify-content: space-between; }

.PassengerTransport_pagination__23yCF {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px; }

.PassengerTransport_datePicker__3pPka {
  width: 100%; }

.PassengerTransport_subtitle__3Euxh {
  margin-top: 16px;
  margin-left: 12px; }

.PassengerTransport_row__1F_9L {
  height: 60px; }

.PassengerTransport_rowHead__3F3Oo {
  background-color: #dbdbdb;
  height: 70px; }

.PassengerTransport_localitySelect__7IiaT {
  width: 100%;
  overflow: hidden;
  position: relative; }

.PassengerTransport_wrapRoutesTable__XyIFd {
  margin: 30px 0px; }

.PassengerTransport_button__gq59M {
  margin-top: 16px; }

.PassengerTransport_box__2XCPw {
  display: grid;
  grid-template-columns: 30% 20% 20% 8%;
  grid-column-gap: 16px;
  margin-bottom: 16px; }

.PassengerTransport_rangePicker__1-iuQ {
  width: 100%; }

.PassengerTransport_select__3wOMm {
  width: 100%; }

.PassengerTransport_popup__YoIKS {
  width: -webkit-max-content;
  width: max-content; }

.UserActions_box__1zVxK {
  padding: 12px; }

.UserActions_checkboxGroup__8IlUQ {
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto; }
  .UserActions_checkboxGroup__8IlUQ label {
    margin-top: 8px; }

.UserActions_divider__3N5JQ {
  margin: 12px 0px; }

.UserActions_actions__3kb-y {
  display: flex;
  align-items: center;
  margin-bottom: 20px; }

.UserActions_actionObject__1c4ic {
  margin-bottom: 4px; }
  .UserActions_actionObject__1c4ic span {
    color: #40a9ff; }

.UserActions_errorTitle__3rCUq {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }

